/* .reviewTextContainer {
    width: 500px;
    text-align: center;
} */

.reviewsIntroContainer {
    margin-top: 8px;
}

.reviewsContainer {
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.reviewSliderContainer {
    /* display: flex; */
    width: 650px;
}

.reviewSliderDots li.slick-active button:before {
    color: #ff9016 !important;
}

.reviewSliderDots button:before {
    color: #c2c2c2 !important;
}

.reviewSliderDots li button:before {
    font-size: 13px !important;
}

.wingContainer {
    padding-top: 64px;
}

.reviewTitle {
    display: block;
    font-size: 40px;
    color: #444444;
    margin-bottom: 32px;
    font-weight: bold;
}

.reviewRate {
    font-size: 39px;
    color: #FFB600;
}

.reviewTextContainer {
    position: relative;
    margin-top: 48px;
    margin-bottom: 58px;
    padding: 0 24px;
    height: 160px;
}

.reviewAuthor {
    display: block;
    font-size: 16px;
    color: #707070;
    margin-top: 63px;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);

}

.withTickleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    padding: 24px 48px;
    background: #FFF7EE;
    border-radius: 60px;

}

.withTickleText {
    font-size: 32px;
    text-align: center;
    color: #FF9016;
    font-weight: 400;
    white-space: pre-wrap;
    display: block;
}

.reviewContent {
    width: 10px;
    font-size: 20px;
    letter-spacing: -0.37px;
    text-align: center;
    color: #210000;
    white-space: pre-wrap;  
}

.withTickleIcons {
    margin-left: 24px;
}

@media  (min-width: 320px) and (max-width: 767px) {
    .reviewTextContainer {
        margin-top: 24px;
    }
    .reviewTitle{
        margin-bottom: 16px; 
        font-size: 16px;
    }
    .reviewRate {
        font-size: 16px;
    }
    .reviewAuthor {
        font-size: 10px;
        bottom: -10px;
        /* margin-bottom: 12px; */
    }
    .reviewSliderDots li {
        margin-left: 2px !important;
        margin-right: 2px !important;
    }
    .reviewSliderDots li button:before {
        font-size: 8px !important;
    }
    .reviewSliderContainer {
        width: 100%;
    }
    .wing {
        display: none;
        width: 10px;
    }
    .withTickleText {
        font-size: 29px;
    }
    .reviewContent {
        font-size: 15px;
    }
    .withTickleContainer {
        padding: 8px 16px;
    }
    .withTickleText {
        font-size: 15px;
    }
    .withTickleIcons {
        display: none;
    }
}