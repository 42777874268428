.letterWelcome {
  font-size: 40px;
  color: #444444;
  white-space: pre-wrap;
  margin-bottom: 24px;
}

.letterWelcomeSub {
  font-size: 21px;
  color: #444444;
  white-space: pre-wrap;
  margin-bottom: 48px;

}

.letterContainer { 
  display: flex;
  height: 100%;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  padding-bottom: 150px;
}

@media  (min-width: 320px) and (max-width: 767px) {
  .letterContainer{ 
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .letterWelcome {
    font-size: 21px;
  }
  .letterWelcomeSub {
    font-size: 15px;
  }

  .letterButtons {
    width: 100%
  }
}