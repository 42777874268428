.buttonBottomFixed {
  width: 100%;
  position: fixed;
  bottom:0;
  left:0;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.2);
  z-index: 100;
}

@media  (min-width: 767px) {
  .buttonBottomFixed{
    display: none;
  }
}