.footer {
  /* width: 100%; */
  /* height: 300px; */
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #444444;
}

.footerText {
  font-family: 'Noto Sans KR', 'sans-serif';
  margin: 0;
  color: #4f4f4f;
  font-size: 13px;
  word-break: keep-all;
  color: #C3C3C3;
}

.footerLink {
  font-family: 'Noto Sans KR', 'sans-serif';
  text-decoration: none;
  color: #4f4f4f;
  font-size: 13px;
  color: #ffffff;
}

.footerLink:hover {
  text-decoration: underline;
}

.footerLink:visited {
  color: #4f4f4f;
}


@media  (min-width: 320px) and (max-width: 767px) {
  .footer {
    padding-bottom: 150px;
  }
}