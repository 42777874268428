.featureIntroContainer {
    padding-bottom: 164px;
}

.mockupCardContainer{
    position: relative;
    width: 100%;
    height: 920px;
    /* background-color: #ff9016; */
}

.infoTab {
    min-width: 0px !important;
}

.introTitleIconContainer {
    width: 97px;
    height: 120px;;
    margin: 0 auto;
}

.introTitleIcon {
    width: 100%;
}

.infoTabsContainer{
    width: 330px;
    margin-top: 36px;
    margin-bottom: 20px;
}

.infoTabsIndicator{
    background-color: #000 !important;
}

.infoTab .MuiTab-wrapper {
    font-size: 18px;
}
.infoTab:not(.Mui-selected) .MuiTab-wrapper {
    color: rgba(69,79,93,.5)
}

.mockup {
    width: 440px;
    position: absolute;
    left: -100px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.cards {
    position: absolute;
    top: 25%;
    left: 185px;
}

.screenshotSlider {
    width: 270px;
    height: 555px;
    position: relative;
    left: 170px;
    top: 111.5px;
}
/* .screenshotSlider .slick-slide{ */
    /* left: 0 !important; */
/* } */

.screenshotSlider .slick-track {
    height: 555px;
    /* background-color: white; */

}

.screenshotContainer{
    position: absolute;
    top: 0;
    left: 0;
}
.screenshot {
    width: 100%;
}
.company {
    display: block;
    margin-top: 55px;
}
.featureTexts {
    /* width: 353px */
}
.featureInnerText {
    width: 353px;
    height: 331px
}

.featureTitle {
    text-align: center;
}

.featureContentContainer {
    width: 100%;
    text-align: center;
}

.featureDivider {
    position: absolute;
    top: 24px;
    left: 0px;
    width: 100%;
    height: 8px;
    background-color: #FBFBFB;
}

@media  (min-width: 320px) and (max-width: 767px) {
    .spareHalf.featureRight {
        height: 280px;
    }
    .introTitleIconContainer {
        width: 60px;
        height: 70px;
        margin: 0 auto;
    }    
    .mockup {
        width: 400px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .screenshotSlider {
        width: 180px;
        height: auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .infoTab .MuiTab-wrapper {
        font-size: 15px;
    }
    .infoTabsContainer {
        width: 100%;
        margin-top: 10px;
    }
    .cards {
        width: 200px;
        top: 5%;
        left: 45%;
    }
    .company {
        display: none;
    } 
    .mockupCardContainer {
        height: 276px;
        top: 33px
    }
    .mockup {
        width: 266px;
        position: absolute;
        /* left: -100px; */
        left: 44%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }
    
    .screenshotSlider {
        width: 156px;
        height: 105px;
        position: absolute;
        left: 50%;
        top: 4.3%;
        transform: translate(-50%, -50%);

        background-color: white;
    }
    
    .screenshotSlider .slick-track {
        height: 335px;
    }

    .featureInnerText {
        width: 100%;
        height: 204px;
        text-align: center !important;
    }

    .featureTexts {
        width: 100%;
    }
    .featureInnerText .introContent {
        text-align: center;
        font-size: 14px;
    }
    .featureIntroContainer {
        padding-bottom: 48px;
    }
}

