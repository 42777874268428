.topBgContainer {
  width: 100%;
  height: 80%;
  position: relative;
  background-color: #ff9016;
};
.topBg{
  width: 100%;
}
.topAreaContainer {
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}
.iconContainer {
  display: flex;
  flex: 1;
  margin-top: 0;
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  top: 170px;
  left: 0px;
}
.icons{
  margin-top:62px;
}
.play-icon {
  width: 28px;
}
.icon{
  width: 95px;
  height: 95px;
}
.logo{
  width: 180px;
  margin-left: 1px;
}

.sloganContainer {
  position: relative;
}

.stirkeSlogan{
  display: relative;
}

.strikeLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 350px;
  height: 27px;
  z-index: 0;
  background-color: #ff9016;
}

.slogan{
  position: relative;
  color: #fff;
  font-size: 60px;
  margin: 0;
  z-index: 1;
}

.slogan.strike{
  font-weight: bold;
}

/* .slogan.strike::after{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-bottom: 27px solid #ff9016;
  content: "";
  margin-top: calc(18px / 2 * -1);
  width:360px;
  z-index: -1;
} */

.handContainer{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  height:80%;
}
.hand {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  position: absolute;
  bottom: 0;

}

.arrow
{
  position: absolute;
  /* bottom: -2rem; */
  bottom: 70px;
  left: 50%;
  margin-left:-20px;
  width: 45px;
  height: 45px;
  z-index: 1;

  /**
   * Dark Arrow Down
   */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' viewBox='0 0 129 129' enable-background='new 0 0 129 129'%3E%3Cg%3E%3Cpath d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='%23fff' /%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
}

.bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  /* 0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  } */
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}



@media  (min-width: 320px) and (max-width: 767px) {
  .iconContainer {
    padding: 20px;
    position: absolute;
    top: 12%;
  }
  .topAreaContainer {
    display: block;
    padding-top: 30px;
    height: 100%;
  }
  .slogan{
    color: #fff;
    font-size: 28px;
    z-index: 1;
  }
  .slogan.strike::after {
    width: 180px;
    top: 58%;
    border-bottom-width: 14px;
  }
  .icons{
    display: none;
  }
  .topBgContainer {
    height: calc(100% - 124px);
  }
  .topBgContainer::before {
    display: block;
    content: '';
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .arrow{
    width: 30px;
    height: 30px;  
  }
  .handContainer{ 
    width: 100%;
    text-align: center;
  }
  .hand {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 90%;
  }
}