.fullsizeImage {
  width: 100%;
}

.uosgyButton {
  width: auto;
  cursor: pointer;
}

.mobile {
  display: none;
}

.pc {
  display: block;
}

@media  (min-width: 320px) and (max-width: 767px) {
  .mobile {
    display: block;
  }
  .pc {
    display: none;
  }
  .uosgyButton {
    width: 50%;
  }    
}