.root {
  flex-grow: 1;
  height: 100%;
  background-color: #fff;
}
#root {
  height: 100%;
}

.columnInMobile {
  flex-direction: row;
}

.columnInMobileReverse {
  flex-direction: row;
}

@media (min-width: 320px) and (max-width: 767px) {
  .columnInMobile {
    flex-direction: column;
    justify-content: center;
  }
  .columnInMobileReverse {
    flex-direction: column-reverse;
  }

  .customModal {
    max-width: 80vw;
  }
}

.react-responsive-modal-root {
  z-index: 100000 !important;
}
