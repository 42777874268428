.root{
  flex-grow: 1;
}
.AppBar{ 
  position: absolute;
  background-color: #fff !important;
  /* background-color: #ff9016 !important; */
  box-shadow: none !important;
  padding: 9px 0px;
}

.MuiToolbar-gutters {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/* .AppBar.orange {
  padding: 0px;
} */

.navLink {
  color:#fff;
  text-decoration: none;
  font-weight: 400;
  margin-left: 32px;
  font-size: 18px;
  font-weight: 400;
  
}
.navLink.orange {
  color:#000;
  text-decoration: none;
}
.appbarLogo {
  width: 96px;
}

.appbarBadge .MuiBadge-badge{
  min-width: 15px;
  height:  15px;
  /* background-color: #0C86B3; */
  background-color: #ff9016;
}

@media  (min-width: 320px) and (max-width: 767px) {
  .AppBar{ 
    padding: 13px 4px;
  }
  .appbarLogo {
    width: 64px;
  }

  .appbarIcon {
    width: 100px;
  }
  .navLink {
    font-size: 15px;
    margin-left: 19px;
  }
  .MuiToolbar-root {
    min-height: 0 !important;
  }
}