.faqContainer {
  /* margin-top: 120px; */
  padding-bottom: 100px;
}
.menuTitle{
  padding-top: 250px;
  margin-bottom: 64px;
  font-weight: 300;
  font-size: 60px;
  margin-top: 0;
}
.questionTitle{
  font-weight: 500;
}

.faqAnswerContainer{
  flex-direction: column
}

@media  (min-width: 320px) and (max-width: 767px) {
  .faqContainer{
    /* margin-top: 48px; */
  }

  .menuTitle{
    padding-top: 100px;
    margin-bottom: 32px;
    font-weight: 300;
    font-size: 29px;
  }
}