.introContainer {
  display: flex;
  flex: 1;
  /* min-height: 920px; */
  padding-top: 100px;
  padding-bottom: 100px;
  height: 60%;
  position: relative;
}

.introContainer.white {
  background-color: #fff;
}

.introContainer.gray {
  background-color: #f8f8f8;
}

.spareCard {
  display: block;
  background-color: #fefefe;
  margin-bottom: 8px;
  flex-direction: column;
  border-radius: 13px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);  
  width: 360px;
  padding: 12px  24px 12px 24px;
}

.spareTop {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.spareHalf {
  display: flex;
  flex: 1; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center
}

.spareBranch {
  display: flex;
  font-size: 16px;
}

.spareSpare {
  display: flex;
  font-size: 16px;
  color: #ff9016;
  font-weight: bold;
}

.spareTransAt{
  display: flex;
  font-size: 16px;
  color: #aeaeae;
}

.spareAmount{
  display: flex;
  font-size: 16px;
}

.introTexts {
  text-align: center;
}

.introTexts.left {
  text-align: left;
}

.titleIcon {
  width: 97px;
  margin-bottom: 16px;
}

.introTitle {
  font-size: 40px;
  line-height: 1.3;
  letter-spacing: -0.92px;
  text-align: left;
  color: #210000;
  margin-top: 0;
  margin-bottom: 21px;
  white-space: pre-wrap;
}

.introContent {
  font-size: 20px;
  letter-spacing: -0.37px;
  text-align: center;
  color: #210000;
  white-space: pre-wrap;
}

.introContent.left {
  text-align: left;
}

.intro-mockup {
  -webkit-filter: drop-shadow(-35px 20px 30px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(-20px 20px 15px rgba(0, 0, 0, 0.2));
}

.availableCardsTextConatiner {
  height: 26px;
  border-radius: 5px;
  background-color: #f8f8f8;
  padding: 4px 16px;
  text-align: center;
  margin-top: 48px;
}

.availableCardsTextConatiner span {
  color: #979797;
  font-size: 12px;
  font-weight: bold;
}

.availableCardsImg {
  width: 300px;
  margin-top: 24px;
}

.grayText {
  color: #979797;
}

.intro-mockup {
  width: 270px;
}

.stockList {
  width: 276px;
  margin-top: 32px;
}

@media  (min-width: 320px) and (max-width: 767px) {
  .introContainer {
    flex: none;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 0;
    height: auto;
  }
  .spareHalf {
    /* flex-grow: 0; */
    flex: none;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .spareCard {
    width: 250px;
  }
  .spareBranch {
    font-size: 14px;
  }
  .spareTransAt {
    font-size: 14px;
  }
  .spareAmount {
    font-size: 14px;
  }
  .spareSpare {
    font-size: 14px;
  }
  .introTitle {
    text-align: center;
    font-size: 21px;
    margin-bottom: 16px;
  }
  .introContent {
    font-size: 15px;
  }
  .pig {
    width: 80%;
  }
  .intro-mockup{
    width: 50%;
  }
  .titleIcon {
    width: 60px;
  }

  .availableCardsImg {
    width: 90%;
  }
}